<script setup lang="ts">
import { getCardTypeByNumber } from '@eonx-com/payment-elements'
import { computed } from 'vue'
import { cardCode } from '/~/utils/cards'
import { formatDate } from '/~/utils/format/date'
import { formatDollar } from '/~/utils/format/money'
import { formatPhone } from '/~/utils/format/string'
import BaseLink from '/~/components/base/link/base-link.vue'
import { ActivityStatus } from '/~/composables/activity/core/ActivityStatus'
import { useLocalization } from '/~/composables/localization'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

const props = defineProps<{
  content: any
}>()

const {
  providerTitle,
  taxationLabel,
  taxationPercentage,
  isUserAddressEnabled,
} = useProvider()
const { translate } = useLocalization()
const { user } = useUser()

const activity = computed(() => props.content.activity || {})
const statusLabel = new ActivityStatus(
  (activity.value.status ?? props.content.status ?? '').toLowerCase(),
  activity.value.type
)?.statusLabel

const billingAddress = computed(() => {
  const {
    firstName,
    lastName,
    streetAddress,
    postcode,
    suburb,
    state = '',
    email,
    mobile,
    companyName,
    accountNumber,
  } = props.content.address || {}

  return [
    [firstName, lastName].filter(Boolean).join(' '),
    companyName,
    [streetAddress, suburb, `${postcode},`, state.toUpperCase()]
      .filter(Boolean)
      .join(' '),
    accountNumber,
    email,
    formatPhone(mobile),
  ].filter(Boolean)
})
const paymentMethods = computed(() => {
  const defaultMethods = [activity.value.payment_method].filter((m) => m)
  const paymentMethods =
    activity.value.displayPaymentMethods ||
    props.content.paymentMethods ||
    defaultMethods

  interface PaymentMethod {
    type: string
    number: string
    brand?: string
    title?: string
    msfFee?: number
    subtitle?: string
    prefix?: string
    bsb?: number
    amount: number
  }

  return paymentMethods
    .map((method: PaymentMethod) => {
      let result: PaymentMethod

      if (method.type) {
        result = {
          ...method,
        }
      } else {
        result = {
          type: method.toString(),
          amount: 0,
          number: '',
        }
      }

      const issuingNetwork = getCardTypeByNumber(method.number)
      const brand = cardCode({ brand: issuingNetwork?.type })

      if (brand) {
        result.brand = `cards/${brand}`
      }

      const { number = '' } = result

      switch (result.type) {
        case PaymentMethodType.points:
          result.title = 'Points'
          break
        case PaymentMethodType.creditCard:
          result.title = brand || 'Credit Card'
          result.msfFee = method.msfFee
          result.subtitle = number.replace(/(.{4})(.*)(.{4})/, '$1 XXXXXX X$3')
          break
        case PaymentMethodType.bankAccount:
          result.title = 'Bank Account'
          result.msfFee = method.msfFee
          result.subtitle = translate('bankAccount.details', {
            acc: `*** *** *${number.slice(-2)}`,
            bsb: result.bsb || result.prefix,
          }).toString()
          break
      }

      return result
    })
    .filter((i: { amount: number }) => i.amount > 0)
})

const fees = computed(() => props.content.fees?.items || [])
const paymentFees = computed(() => {
  let amount = getFeesByType('Processing Fee')

  if (user.value.isCombineFees) {
    amount += programFees.value
  }

  return amount
})

const totalFees = computed(() => {
  if (user.value.isCombineFees) {
    return paymentFees.value
  }

  return paymentFees.value + programFees.value
})

const taxNumber = computed(() => props.content?.business?.taxNumber)

const paymentFeesGst = computed(() =>
  getGst(paymentFees.value, taxationPercentage.value)
)
const showProgramFees = computed(
  () => programFees.value > 0 && !user.value.isCombineFees
)
const programFees = computed(() => getFeesByType('Program Fee'))
const programFeesGst = computed(() =>
  getGst(programFees.value, taxationPercentage.value)
)
const totalGst = computed(() => {
  if (user.value.isCombineFees) {
    return paymentFeesGst.value
  }

  return programFeesGst.value + paymentFeesGst.value
})

const supportEmail = computed(() => eonx.config?.general?.general?.email)

function getFeesByType(label: string) {
  const feesByType = fees.value.filter(
    (i: { label: string }) => i.label === label
  )

  return feesByType.reduce((value: number, fee: { amount: number }) => {
    return Number(fee?.amount ?? 0) + value
  }, 0)
}

function getGst(value: number, taxPercentage: number) {
  const valueExcludingTax = value / (1.0 + taxPercentage / 100)

  return value - valueExcludingTax
}
</script>

<template>
  <div>
    <div class="space-y-[15px]">
      <div class="border-b pb-[15px] text-sm leading-7">
        <p data-testid="date-time">
          <strong>Date:</strong>
          {{ formatDate('daymonthyearlongtime', content.date) }}
        </p>
        <p>
          <strong>Transaction No.</strong>
          {{ activity.number || content.number }}
        </p>
        <p>
          <strong class="mr-1">Status:</strong>
          <span :class="[statusLabel.color]">
            {{ statusLabel.text }}
          </span>
        </p>
      </div>

      <div class="flex justify-between">
        <div>
          <div v-if="isUserAddressEnabled && billingAddress.length">
            <strong>Billing:</strong>
            <div
              v-for="(item, index) of billingAddress"
              :key="`address-item-${index}`"
            >
              {{ item }}
            </div>
          </div>
        </div>

        <div v-if="taxNumber" data-testid="tax-registration">
          <strong>{{ taxationLabel }} Registration:</strong>
          {{ taxNumber }}
        </div>
      </div>
    </div>
    <table class="w-full">
      <tr>
        <th class="w-2/6 py-[15px] pl-[15px]" />
        <th class="w-1/6 py-[15px] pl-[15px]" />
        <th class="w-1/6 py-[15px] pl-[15px]" />
        <th class="w-1/6 py-[15px] pl-[15px]" />
        <th class="w-1/6 py-[15px] pl-[15px]" />
      </tr>
      <tr>
        <td colspan="5" class="py-[15px] pl-[15px]">
          <p class="mt-6 text-left font-bold">
            <span v-if="totalFees">
              Tax Invoice breakdown for {{ providerTitle }} services as
              calculated below
            </span>
            <span v-else>
              This tax invoice/payment receipt does not contain any fees
            </span>
          </p>
        </td>
      </tr>
      <tr v-if="showProgramFees">
        <td
          colspan="4"
          class="py-[15px] pl-[15px]"
          data-testid="program-fees-label"
        >
          Program fees (inc. {{ taxationLabel }})
        </td>
        <td class="py-[15px] pl-[15px] text-right">
          {{ formatDollar(programFees) }}
        </td>
      </tr>
      <template v-for="(paymentMethod, index) of paymentMethods">
        <tr
          v-if="
            [
              PaymentMethodType.creditCard,
              PaymentMethodType.bankAccount,
            ].includes(paymentMethod.type) &&
            (paymentMethod.msfFee > 0 || paymentMethod.paidMsfFee > 0)
          "
          :key="index"
        >
          <td
            v-if="paymentMethod.type === PaymentMethodType.creditCard"
            colspan="4"
            class="py-[15px] pl-[15px]"
            data-testid="cc-processing-fee-label"
          >
            Card Processing Fee (inc. {{ taxationLabel }})
          </td>
          <td
            v-else-if="paymentMethod.type === PaymentMethodType.bankAccount"
            colspan="4"
            class="py-[15px] pl-[15px]"
            data-testid="bank-processing-fee-label"
          >
            Bank Processing Fee (inc. {{ taxationLabel }})
          </td>
          <td class="py-[15px] pl-[15px] text-right">
            {{ formatDollar(paymentMethod.msfFee || paymentMethod.paidMsfFee) }}
          </td>
        </tr>
      </template>
      <tr v-if="totalGst">
        <td colspan="2" class="py-[15px] pl-[15px]" />
        <td colspan="2" class="py-[15px] pl-[15px]">
          <strong data-testid="total-tax-label">
            {{ taxationLabel }} (the {{ taxationLabel }} portion of total)
          </strong>
        </td>
        <td
          class="py-[15px] pl-[15px] text-right text-2xl"
          data-testid="total-tax"
        >
          {{ formatDollar(totalGst) }}
        </td>
      </tr>
      <tr v-if="totalFees">
        <td colspan="2" class="py-[15px] pl-[15px]" />
        <td colspan="2" class="py-[15px] pl-[15px]">
          <strong data-testid="total-fees-label">
            Total inc. {{ taxationLabel }} (Program fees + Processing fees)
          </strong>
        </td>
        <td class="py-[15px] pl-[15px] text-right text-2xl">
          <strong>{{ formatDollar(totalFees) }}</strong>
        </td>
      </tr>
    </table>
    <div class="my-6 border-t pt-6">
      <p class="mb-2.5">
        <strong>Please note:</strong>
      </p>
      <p class="mb-2.5" data-testid="foot-note-1">
        {{ providerTitle }} is a payment facilitator which processes
        transactions on behalf of participating payees. All bill payments made
        to respective payees carry their own {{ taxationLabel }} calculations as
        per the payee’s issued tax invoice. {{ providerTitle }} processes bill
        payments in accordance with banking settlement arrangements and
        transaction times. For any questions, please refer to the
        {{ providerTitle }} Terms & Conditions, or simply contact us at
        <base-link :href="`mailto:${supportEmail}`">
          {{ supportEmail }}
        </base-link>
      </p>
      <p data-testid="foot-note-2">
        * Refer to payees tax invoice for {{ taxationLabel }} calculations
      </p>
    </div>
  </div>
</template>
